import { ProgramTypesEnum, ProgramDurationsEnum } from '@shared/enums';
import { CurrentProgram } from '@shared/models';

export function convertParamsIntoSetProgram(
  menuType: ProgramTypesEnum,
  weeksLength: '1' | '2' | '4',
): { id?: ProgramTypesEnum; durationId?: ProgramDurationsEnum } {
  const partial: Partial<CurrentProgram> = {};
  const type = formatedString(menuType) as ProgramTypesEnum;

  if (
    Object.values(ProgramTypesEnum)
      .map(val => val)
      .includes(type)
  ) {
    partial.id = type;
  }

  if (['1', '2', '4'].includes(weeksLength)) {
    const durationIds = {
      '1': ProgramDurationsEnum.oneWeek,
      '2': ProgramDurationsEnum.twoWeeks,
      '4': ProgramDurationsEnum.fourWeeks,
    };

    partial.durationId = durationIds[weeksLength];
  }

  return partial;
}

/** Метод меняет первую букву на заглавную */
export function formatedString(menuType: string): string {
  if (!menuType) {
    return '';
  }

  return menuType.charAt(0).toUpperCase() + menuType.slice(1);
}
