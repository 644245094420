import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, finalize, tap, throwError } from 'rxjs';
import { Keyboard } from '@capacitor/keyboard';
import { Preferences } from '@capacitor/preferences';
import { Device } from '@capacitor/device';
import { State, Action, StateContext, NgxsOnInit } from '@ngxs/store';

import {
  InitAgreements,
  SendInputPhone,
  SetAuth,
  SetNotificationsAgreement,
  SetPrivacyAgreement,
  SetUrlAfterAuth,
  VerifyCode,
} from './auth.actions';
import { getFormattedPhone } from '@shared/utils';
import { AuthService, RudderStackService } from '@shared/services';
import { TimerService } from '@modules/login/services/timer.service';
import { CommonEvents, LocalStorageKeysEnum } from '@shared/enums';
import { AuthData } from '@shared/models';
import { AuthEventEnum } from '@modules/client-menu/enums';

export interface AuthStateModel {
  inputPhone: string;
  isAuthorized: boolean;
  urlAfterAuth: { commands: any[]; extras?: NavigationExtras };
  isGettingCode: boolean;
  isSendingCode: boolean;
  isCodeSent: boolean;
  codeError: string;
  authErrorMessage: string;
  isShowAgreements: boolean;
  privacyAgreement: boolean;
  notificationsAgreement: boolean;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    inputPhone: '',
    isAuthorized: false,
    urlAfterAuth: null,
    isGettingCode: false,
    isSendingCode: false,
    isCodeSent: false,
    codeError: '',
    authErrorMessage: '',
    isShowAgreements: true,
    privacyAgreement: true,
    notificationsAgreement: true,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private timerService: TimerService,
    private rudderstack: RudderStackService,
  ) {}

  async ngxsOnInit({ dispatch }: StateContext<AuthStateModel>): Promise<void> {
    const isAuthorized = await this.authService.doesTokenExist();

    if (!isAuthorized) {
      this.router.navigate(['login', 'phone']);
    }

    dispatch(new SetAuth(isAuthorized));
  }

  @Action(SetAuth)
  setAuth({ patchState }: StateContext<AuthStateModel>, { isAuthorized }: SetAuth): void {
    patchState({
      isAuthorized,
    });
  }

  @Action(SendInputPhone)
  sendInputPhone({ getState, patchState }: StateContext<AuthStateModel>, { inputPhone }: SendInputPhone): Observable<unknown> {
    this.timerService.clear();

    const { inputPhone: prevPhone } = getState();

    if (inputPhone) {
      patchState({ inputPhone });
    }

    patchState({
      codeError: '',
      authErrorMessage: '',
      isGettingCode: true,
    });

    const formattedPhone = getFormattedPhone(inputPhone || prevPhone);

    return this.authService.sendConfirmationCode(`7${formattedPhone}`).pipe(
      tap(() => {
        this.rudderstack.track(CommonEvents.showLoginCode);
        this.timerService.start(inputPhone);

        this.router.navigate(['login', 'code'], { skipLocationChange: true });
      }),
      catchError(({ error }: HttpErrorResponse) => {
        patchState({
          codeError: error?.error,
        });

        return throwError(() => new Error(error?.error));
      }),
      finalize(() => {
        patchState({
          isGettingCode: false,
          isCodeSent: true,
        });
      }),
    );
  }

  @Action(VerifyCode)
  verifyCode({ getState, patchState }: StateContext<AuthStateModel>, { code }: VerifyCode): Observable<AuthData> {
    const { inputPhone, privacyAgreement, notificationsAgreement, urlAfterAuth } = getState();
    const formattedPhone = getFormattedPhone(inputPhone);

    patchState({ isSendingCode: true });

    return this.authService.verifyCode(`7${formattedPhone}`, code, privacyAgreement, notificationsAgreement).pipe(
      tap(async ({ isFirstAuthorization }) => {
        this.timerService.stop();

        patchState({ inputPhone: '' });

        const { platform } = await Device.getInfo();

        if (platform !== 'web') {
          Keyboard.hide();
        }

        this.rudderstack.track(AuthEventEnum.sendSmsCode);

        if (urlAfterAuth) {
          this.router.navigate(urlAfterAuth.commands, urlAfterAuth.extras);
          patchState({ urlAfterAuth: null });

          return;
        }

        this.router.navigate(isFirstAuthorization ? ['cabinet', 'create'] : ['cabinet']);
      }),
      catchError((errorResp: HttpErrorResponse) => {
        const defaultError = 'Ошибка. Что-то пошло не так. Пожалуйста, свяжитесь с нами и мы решим эту проблему';
        const authErrorMessage = errorResp?.error?.error?.split('.')[0] || defaultError;

        patchState({ authErrorMessage });

        return throwError(() => new Error(authErrorMessage));
      }),
      finalize(() => patchState({ isSendingCode: false })),
    );
  }

  @Action(SetUrlAfterAuth)
  setUrlAfterAuth({ patchState }: StateContext<AuthStateModel>, { commands, extras }: SetUrlAfterAuth) {
    patchState({ urlAfterAuth: { commands, extras } });
  }

  @Action(InitAgreements)
  async initAgreements({ patchState }: StateContext<AuthStateModel>): Promise<void> {
    const notificationsAgreement = await Preferences.get({
      key: LocalStorageKeysEnum.notificationsAgreement,
    });

    const privacyAgreement = await Preferences.get({
      key: LocalStorageKeysEnum.privacyAgreement,
    });

    patchState({
      privacyAgreement: privacyAgreement.value === 'true',
      notificationsAgreement: notificationsAgreement.value === 'true',
      isShowAgreements: !(privacyAgreement.value === 'true' || notificationsAgreement.value === 'true'),
    });
  }

  @Action(SetPrivacyAgreement)
  setPrivacyAgreement({ patchState }: StateContext<AuthStateModel>, { isChecked }: SetPrivacyAgreement): void {
    patchState({ privacyAgreement: isChecked });

    Preferences.set({
      key: LocalStorageKeysEnum.privacyAgreement,
      value: String(isChecked),
    });
  }

  @Action(SetNotificationsAgreement)
  setNotificationsAgreement({ patchState }: StateContext<AuthStateModel>, { isChecked }: SetPrivacyAgreement): void {
    patchState({ notificationsAgreement: isChecked });

    Preferences.set({
      key: LocalStorageKeysEnum.notificationsAgreement,
      value: String(isChecked),
    });
  }
}
