import { ChangeDetectionStrategy, Component, ViewChild, ElementRef, OnInit, Self, inject } from '@angular/core';
import { Observable, filter, first, takeUntil, tap } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import dayjs, { Dayjs } from 'dayjs';

import { NgOnDestroyService, RudderStackService } from '@shared/services';
import { ClientSubscriptionDetails, ClientSubscriptionPackage, DayItem } from '@shared/models';
import { ClientMenuSelectors } from '@store/client-menu';
import { getDayItem } from '@shared/utils';
import { ClientMenuEventsEnum } from '@modules/client-menu/enums';
import { LoadingSelectors } from '@store/loading';
import { LoadingTagEnum } from '@shared/enums';

@Component({
  selector: 'app-calendar-selector',
  templateUrl: './calendar-selector.component.html',
  styleUrls: ['./calendar-selector.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarSelectorComponent implements OnInit {
  @ViewChild('list') listRef: ElementRef;

  public dates: DayItem[] = [];

  public activeSubscriptionDetails$: Observable<ClientSubscriptionDetails> = inject(Store).select(ClientMenuSelectors.subscriptionDetails);
  public isSubscriptionDetailsLoading$: Observable<boolean> = inject(Store).select(
    LoadingSelectors.isLoading(LoadingTagEnum.isSubscriptionDetailsLoading),
  );
  public didPageFail$: Observable<boolean> = inject(Store).select(LoadingSelectors.isLoading(LoadingTagEnum.didClientMenuPageFail));
  public activePackageId$: Observable<string> = inject(Store).select(ClientMenuSelectors.activePackageId);

  constructor(@Self() private ngOnDestroy$: NgOnDestroyService, private router: Router, private rudderstack: RudderStackService) {}

  ngOnInit(): void {
    this.listenSubscriptionDetails();
  }

  private listenSubscriptionDetails(): void {
    this.activeSubscriptionDetails$
      .pipe(
        filter<ClientSubscriptionDetails>(Boolean),
        tap(details => this.updateCalendar(details)),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  onSelectPackage(dayItem: DayItem, details: ClientSubscriptionDetails, packageId: string): void {
    // Конвертация для query параметра
    // 2023-03-05xxxxx --> 05-03-2023
    const date = dayItem.dateString.slice(0, 10).split('-').reverse().join('-');
    this.router.navigate(['cabinet', 'menu'], { queryParams: { subscriptionId: details.subscriptionId, date } });

    const isCustomized = details.packageItems.find(({ hasCustomization }) => hasCustomization) !== undefined;
    const isCustomizable = details.packageItems.find(({ isCustomizable }) => isCustomizable) !== undefined;

    this.trackDateClick(packageId, date, isCustomizable, isCustomized);
  }

  private trackDateClick(packageId: string, date: string, isCustomizable: boolean, isCustomized: boolean): void {
    this.rudderstack.track(ClientMenuEventsEnum.dateClick, { packageId, date, isCustomizable, isCustomized });
  }

  private updateCalendar(details: ClientSubscriptionDetails): void {
    if (!details?.packageItems?.length) {
      return;
    }

    const packageItems = details?.packageItems;
    const firstDay = packageItems[0]?.date;
    const lastDay = packageItems[packageItems.length - 1]?.date;

    this.dates = this.getDates(dayjs(firstDay), dayjs(lastDay), details);

    const firstDate = this.dates?.length > 0 ? this.dates[0] : null;

    if (!firstDate) {
      return;
    }

    this.scrollToCurrentItem();
  }

  private getDates(startDay: Dayjs, endDay: Dayjs, subscription: ClientSubscriptionDetails): DayItem[] {
    if (startDay.isSame(endDay)) {
      return [getDayItem(startDay, subscription)];
    }

    const dates = [];
    let currentDate = startDay.clone();

    while (!currentDate.isAfter(endDay)) {
      dates.push(getDayItem(currentDate, subscription));
      currentDate = currentDate.add(1, 'day');
    }

    return dates;
  }

  private scrollToCurrentItem(): void {
    this.activePackageId$.pipe(filter<string>(Boolean), first()).subscribe(id => {
      const itemWidth = 48;
      const offsetX = this.dates.findIndex(date => date.packageId === id) * itemWidth;

      (this.listRef?.nativeElement as HTMLDivElement)?.scrollTo({
        behavior: 'smooth',
        left: offsetX,
      });
    });
  }

  isCustomizePackage(packageId: string, packages: ClientSubscriptionPackage[]): boolean {
    return packages?.find(item => item.packageId === packageId)?.hasConfirmedCustomization || false;
  }
}
