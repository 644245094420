import { ChangeDetectionStrategy, Input, Output, EventEmitter, Component } from '@angular/core';

import { NgOnDestroyService } from '@shared/services';
import { ClientSubscription } from '@shared/models';
import { ProgramDurationNames, ProgramModesEnum, ProgramDurationsEnum, SubscriptionStatusEnum } from '@shared/enums';
import { formatTime, formatDeliveryDays } from '@shared/utils';

@Component({
  selector: 'app-subscription-item',
  templateUrl: './subscription-item.component.html',
  styleUrls: ['./subscription-item.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionItemComponent {
  @Input() data: ClientSubscription;
  @Input() supportLink = false;
  @Input() isSmall = false;
  @Output() goToDetail = new EventEmitter<ClientSubscription>();

  public SubscriptionStatusEnum = SubscriptionStatusEnum;

  get programTypeClass(): string {
    return 'menuType--' + this.data?.menuType.toLowerCase();
  }

  get deliveryDays(): string[] {
    return formatDeliveryDays(this.data?.deliveryDays);
  }

  get deliveryTime(): string {
    return formatTime(this.data?.deliveryTimeFrom, this.data?.deliveryTimeTo);
  }

  get noBreakfast(): boolean {
    return this.data?.mode === ProgramModesEnum.NoBreakfast;
  }

  get durationType(): string {
    const value = this.data?.feedDaysCount;

    if (value % 5 === 0) {
      return 'По будням ';
    }

    if (value % 6 === 0) {
      return 'По будням + сб ';
    }

    return 'По будням + сб, вс ';
  }

  get duration(): string {
    const value = this.data?.feedDaysCount;

    if (!value) {
      return null;
    }

    if (value === 1) {
      return ProgramDurationNames[ProgramDurationsEnum.oneDay];
    }

    if (value === 2) {
      return ProgramDurationNames[ProgramDurationsEnum.twoDays];
    }

    if (value <= 7) {
      return ProgramDurationNames[ProgramDurationsEnum.oneWeek];
    }

    if (value <= 14) {
      return ProgramDurationNames[ProgramDurationsEnum.twoWeeks];
    }

    if (value <= 21) {
      return ProgramDurationNames[ProgramDurationsEnum.threeWeeks];
    }

    return ProgramDurationNames[ProgramDurationsEnum.fourWeeks];
  }

  onGoToDetail(): void {
    this.goToDetail.emit(this.data);
  }
}
