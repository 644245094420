import { ChangeDetectionStrategy, EventEmitter, Input, Output, Component, ViewChild } from '@angular/core';
import { NgOnDestroyService } from '@shared/services';

@Component({
  selector: 'app-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrls: ['./top-toolbar.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopToolbarComponent {
  @Input() title = '';
  @Input() isUnpaidSubscription = false;

  @Output() goBack = new EventEmitter();
  @Output() clickRightButton = new EventEmitter();

  @ViewChild('popover') popover;

  public isOpen = false;

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  onGoBack(): void {
    this.goBack.emit();
  }

  handleRightButton(): void {
    this.isOpen = false;
    this.clickRightButton.emit();
  }
}
