import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';
import { DeviceInfo, Device } from '@capacitor/device';
import { Store } from '@ngxs/store';

import { environment } from 'src/environments/environment';
import { AuthData, RefreshTokenResp } from '@shared/models';
import { CookieHeadersEnum, LocalStorageKeysEnum } from '@shared/enums';
import { SetAuth } from '@store/auth';
import { DeleteTokenBinding } from '@store/notifications';
import { AnalyticsService } from '../analytics';
import { ClearClientMenuData, LoadSubscriptions } from '@store/client-menu';
import { RefreshProgramData } from '@store/program';
import { SentryService } from '../analytics/sentry.service';
import { RudderStackService } from './rudderstack.service';
import { AuthEventEnum } from '@modules/client-menu/enums';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private analyticsService: AnalyticsService,
    private rudderstack: RudderStackService,
    private sentryService: SentryService,
  ) {}

  async getToken(): Promise<string> {
    return (await Preferences.get({ key: LocalStorageKeysEnum.token })).value;
  }

  sendConfirmationCode(phone: string): Observable<unknown> {
    return this.http.post<void>(environment.url.password, { phone });
  }

  verifyCode(phone: string, smsCode: string, privacyAgreement: boolean, notificationsAgreement: boolean): Observable<AuthData> {
    const url = environment.url.auth;
    const model = {
      phone,
      smsCode,
      region: 0,
      // agreedToMarketing: privacyAgreement,
      // notificationsAgreement
    };

    return this.http.post<AuthData>(url, model).pipe(
      tap(async ({ accessToken, refreshToken, isFirstAuthorization }: AuthData): Promise<void> => {
        this.authorize(accessToken, refreshToken, isFirstAuthorization);
      }),
    );
  }

  async doesTokenExist(): Promise<boolean> {
    const token = await Preferences.get({
      key: LocalStorageKeysEnum.token,
    });

    return !!token?.value;
  }

  async refreshToken() {
    const token = await Preferences.get({ key: LocalStorageKeysEnum.refreshToken });
    const { platform }: DeviceInfo = await Device.getInfo();
    const isAndroid = platform === 'android';
    const url = environment.url.refresh;
    const appKey = !isAndroid ? environment.appKeyIOS : environment.appKeyAndroid;
    const options = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        appKey,
        authorization: `Bearer ${token.value}`,
        [CookieHeadersEnum.shouldSkipInterceptor]: 'true',
      },
    };

    return firstValueFrom(
      this.http.post<RefreshTokenResp>(url, null, options).pipe(
        tap(resp => {
          Preferences.set({
            key: LocalStorageKeysEnum.token,
            value: resp.accessToken,
          });
        }),
      ),
    );
  }

  async authorize(accessToken: string, refreshToken: string, isFirstAuthorization: boolean) {
    await Preferences.set({
      key: LocalStorageKeysEnum.token,
      value: accessToken,
    });
    await Preferences.set({
      key: LocalStorageKeysEnum.refreshToken,
      value: refreshToken,
    });

    this.rudderstack.track(AuthEventEnum.login);

    this.store.dispatch([new SetAuth(true), new LoadSubscriptions()]);

    if (isFirstAuthorization) {
      this.analyticsService.login();
    }
  }

  async logout(): Promise<void> {
    const token = await this.getToken();
    if (token) {
      firstValueFrom(this.store.dispatch(new DeleteTokenBinding()));
    }

    await this.clearData();

    this.rudderstack.track(AuthEventEnum.logout);

    this.router.navigate(['login']);
  }

  async clearData(): Promise<void> {
    this.store.dispatch([new SetAuth(false), new ClearClientMenuData(), new RefreshProgramData()]);
    this.sentryService.setUser(null);

    await Preferences.remove({ key: LocalStorageKeysEnum.token });
    await Preferences.remove({ key: LocalStorageKeysEnum.refreshToken });
    await Preferences.remove({ key: LocalStorageKeysEnum.deviceInfo });
  }
}
