import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';

import { ClientMenuDish, Feedback, FeedbackDishData, FeedbackDishRequest } from '../models';
import { DishRatingComponent } from '../components';
import { FeedbackApiService } from './feedback-api.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackDishService {
  constructor(private feedbackApiService: FeedbackApiService, private modalCtrl: ModalController) {}

  public openDialog(data: FeedbackDishData): Observable<any> {
    return this.generateDishFeedback(data.packageId, data.dish).pipe(
      filter(Boolean),
      switchMap(feedback => this.open(feedback, data.dish, data.date)),
    );
  }

  private generateDishFeedback(packageId: string, dish: ClientMenuDish): Observable<any> {
    const request: FeedbackDishRequest = {
      packageId,
      dishCode: dish.id,
    };

    return this.feedbackApiService.generateDishFeedback(request);
  }

  async open(feedback: Feedback, dish: ClientMenuDish, date): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: DishRatingComponent,
      cssClass: 'dish-feedback-modal',
      mode: 'md',
      breakpoints: [0, 0.25, 0.5, 1],
      initialBreakpoint: 1,
      componentProps: {
        feedback,
        dish,
        rating: feedback.rating,
        date,
      },
    });
    modal.present();
  }
}
