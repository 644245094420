import { ChangeDetectionStrategy, forwardRef, Output, EventEmitter, Input, Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TogglerComponent),
    },
  ],
})
export class TogglerComponent implements ControlValueAccessor {
  @Input() public set value(data: boolean) {
    if (data !== this.value) {
      this.changeInnerValue(data, true);
    }
  }

  public get value(): boolean {
    return this._value;
  }

  @Input() disabled = false;

  @Output() public changeValue = new EventEmitter<boolean>();

  protected onChange: (value) => void;
  protected onTouched: () => void;
  private _value: boolean;

  public change(data: boolean): void {
    if (this.disabled) {
      return;
    }

    this.changeInnerValue(data);
  }

  public trackByFn(index: number): string {
    return `${index}`;
  }

  private changeInnerValue(data: boolean, isSilent = false): void {
    this._value = data;

    if (!isSilent) {
      this.changeValue.emit(data);
    }

    if (this.onChange) {
      this.onChange(data);
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  // implementation of `ControlValueAccessor`
  writeValue(value: boolean): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
