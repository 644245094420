<ion-app>
  <ion-router-outlet [swipeGesture]="false"></ion-router-outlet>
  <app-admin-container></app-admin-container>

  <app-no-connection *ngIf="!(hasConnection$ | async)"></app-no-connection>

  <ion-modal
    #modal
    cssClass="feedback-modal"
    [isOpen]="isOpenModal$ | async"
    [initialBreakpoint]="0.4"
    [breakpoints]="[0.4, 0.9, 1]"
    [backdropDismiss]="false"
  >
    <ng-template>
      <app-rating-modal
        [feedback]="feedback$ | async"
        [isFeedbackSent]="isFeedbackSent$ | async"
        (setRating)="modal.setCurrentBreakpoint(1)"
        (sendFeedback)="sendFeedback($event, modal)"
        (close)="closeFeedbackModal($event, modal)"
      ></app-rating-modal>
    </ng-template>
  </ion-modal>
</ion-app>
