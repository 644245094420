import { Selector } from '@ngxs/store';

import { FeedbackStateModel } from './feedback.model';
import { Feedback } from '@modules/client-menu/models';

export class FeedbackSelectors {
  @Selector([state => state.feedback])
  static state(state: FeedbackStateModel): FeedbackStateModel {
    return state;
  }

  @Selector([state => state.feedback])
  static feedback(state: FeedbackStateModel): Feedback {
    return state.feedback;
  }

  @Selector([state => state.feedback])
  static isFeedbackSent(state: FeedbackStateModel): boolean {
    return state.isFeedbackSent;
  }

  @Selector([state => state.feedback])
  static isFeedbackModalOpened(state: FeedbackStateModel): boolean {
    return state.isFeedbackModalOpened;
  }
}
