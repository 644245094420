import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventType, Params, Router } from '@angular/router';
import { filter, firstValueFrom } from 'rxjs';
import { DeviceInfo, Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';

import { environment } from 'src/environments/environment';
import { RudderStackService } from '@shared/services';
import { LocalStorageKeysEnum } from '@shared/enums';
import { generateUuid } from '@shared/utils';
import { CommonEventsEnum } from '@modules/client-menu/enums';

@Injectable({
  providedIn: 'root',
})
export class BrowserLoadingScenarioService {
  constructor(private http: HttpClient, private router: Router, private rudderstack: RudderStackService) {}

  async init(): Promise<void> {
    await this.generateVisitorIdId();
    await this.getPageLoadedAccess();

    this.listenRouterNavigate();

    this.rudderstack.track(CommonEventsEnum.pageLoaded, { path: window.location.pathname });
  }

  async generateVisitorIdId(): Promise<void> {
    const result = await Preferences.get({ key: LocalStorageKeysEnum.visitorId });

    let visitorId = result.value || generateUuid();

    await Preferences.set({
      key: LocalStorageKeysEnum.visitorId,
      value: visitorId,
    });
  }

  async getPageLoadedAccess(): Promise<void> {
    const deviceInfo: DeviceInfo = await Device?.getInfo();
    const platform = deviceInfo?.platform;
    const isAndroid = platform === 'android';
    const appKey = !isAndroid ? environment.appKeyIOS : environment.appKeyAndroid;

    setTimeout(() => {
      const pageUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        this.router.url +
        (this.router.url.includes('?') ? '&' : '?') +
        'utm_os=' +
        (!isAndroid ? 'Mobile|IOS' : 'Mobile|Android');

      const params: Params = {
        AppKey: appKey,
        l: pageUrl,
      };

      firstValueFrom(this.http.get<void>(environment.baseApiUrl + 'pageloaded/access', { params, withCredentials: true }));
    }, 50);
  }

  /**
   * Отправляем event при каждом переходе на другую страницу
   */
  private listenRouterNavigate(): void {
    this.router.events.pipe(filter(({ type }) => type === EventType.NavigationStart)).subscribe(event => {
      this.rudderstack.track(CommonEventsEnum.pageChange, {
        prevPage: window.location.pathname + window.location.search,
        currentPage: (event as any).url,
      });
    });
  }
}
