import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Observable, EMPTY, catchError } from 'rxjs';
import { Store } from '@ngxs/store';

import { environment } from 'src/environments/environment';

import {
  AvailableDiscountParams,
  AvailableDiscountParamsOption,
  CurrentProgram,
  ExtOptions,
  ExtOptionsInfo,
  ProgramDurationInfo,
  SubscriptionPriceResponse,
} from '@shared/models';
import {
  DeliveryOptionsEnum,
  LocalStorageKeysEnum,
  ProgramCaloriesEnum,
  ProgramDurationsEnum,
  ProgramModesEnum,
  ProgramTypesEnum,
} from '@shared/enums';
import { deepClone } from '@shared/utils';
import { SubscriptionService } from '@shared/services';
import { DefaultProgramConfig, getProgramViewAvailableData } from '@shared/data';
import { ProgramStateModel } from './program.model';

@Injectable({
  providedIn: 'root',
})
export class ProgramHelpers {
  constructor(private store: Store, private subscriptionService: SubscriptionService) {}

  async getInitialProgram(): Promise<Partial<CurrentProgram>> {
    const storedProgram = await Preferences.get({ key: LocalStorageKeysEnum.currentProgram });
    const hasStoredValue = storedProgram && storedProgram.value;

    if (!hasStoredValue) {
      return DefaultProgramConfig;
    }

    let config: Partial<CurrentProgram>;

    try {
      config = JSON.parse(storedProgram.value);
    } catch (e) {
      config = DefaultProgramConfig;
    }

    if (!config.extOptions) {
      config.extOptions = {
        autoRenew: false,
        eatOnSaturday: false,
        eatOnSaturdayAndSunday: false,
        noBreakfastAndDinner: config?.mode === ProgramModesEnum.NoBreakfast,
      };
    }

    if (typeof config.extOptions === 'object') {
      config.extOptions = {
        autoRenew: Boolean(config.extOptions?.autoRenew?.['isChecked']),
        eatOnSaturday: Boolean(config.extOptions?.eatOnSaturday?.['isChecked']),
        eatOnSaturdayAndSunday: Boolean(config.extOptions?.eatOnSaturdayAndSunday?.['isChecked']),
        noBreakfastAndDinner: config?.mode === ProgramModesEnum.NoBreakfast,
      };
    }

    return config;
  }

  /**
   * Метод модифицирует текущую программу новыми данными
   * @param program текущая программа
   * @param data обновления программы
   * @returns обновленная программа
   */
  public getUpdatedProgram(program: CurrentProgram, data: Partial<CurrentProgram>, isPromocode: boolean): CurrentProgram {
    // Для сплитуемых (т.е. оплата будет производиться частями) подписок необходима продолжительность в 4 недели
    const isSplittable = Boolean(data?.isSplittable) || Boolean(program?.isSplittable);

    const durationId = isSplittable
      ? data?.durationId && [ProgramDurationsEnum.fourWeeks, ProgramDurationsEnum.twoMonth].includes(data?.durationId)
        ? data?.durationId
        : [ProgramDurationsEnum.fourWeeks, ProgramDurationsEnum.twoMonth].includes(program.durationId)
        ? program.durationId
        : ProgramDurationsEnum.fourWeeks
      : data?.durationId || program.durationId;
    // ------

    const extOptions = { ...program?.extOptions, ...data?.extOptions };
    const durationInDays = data.durationInDays || this.calculateDurationInDays(durationId, extOptions);

    const updatedProgram: CurrentProgram = {
      ...program,
      ...data,
      durationId,
      durationInDays,
      extOptions,
    };

    // У Разнообразного 1200 и 1500 нет опции 'Без завтраков и ужинов'
    // Для подписок на два дня также не может быть режима "Без завтраков и ужинов"
    if (
      (updatedProgram.extOptions.noBreakfastAndDinner &&
        updatedProgram.id === ProgramTypesEnum.Fit &&
        [ProgramCaloriesEnum.cal1200, ProgramCaloriesEnum.cal1500].includes(updatedProgram.dailyCalories)) ||
      updatedProgram.durationId === ProgramDurationsEnum.twoDays
    ) {
      updatedProgram.extOptions.noBreakfastAndDinner = false;
    }
    // ------

    // Если меняем тип подписки, то сбрасываем режим "Без завтраков и ужинов"
    if (data.id) {
      updatedProgram.extOptions.noBreakfastAndDinner = false;
      updatedProgram.mode = ProgramModesEnum.Full;
    }

    // Если включен режим "Без завтраков и ужинов", то нужно переключить mode!
    if (extOptions.noBreakfastAndDinner) {
      updatedProgram.mode = ProgramModesEnum.NoBreakfast;
    }

    // Нужно переключить mode, если режим "Без завтраков и ужинов" выключили!
    else if (updatedProgram.mode === ProgramModesEnum.NoBreakfast) {
      updatedProgram.mode = ProgramModesEnum.Full;
    }
    // -------

    // Бонусы нельзяи спользовать, если есть промокод
    if (isPromocode) {
      updatedProgram.applyBonuses = false;
    }

    return updatedProgram;
  }

  public getTotalDurationInDays(durationId: ProgramDurationsEnum, extOptions: ExtOptions): number {
    const { eatOnSaturday, eatOnSaturdayAndSunday } = extOptions;
    const saturday = eatOnSaturday || eatOnSaturdayAndSunday;
    const sunday = eatOnSaturdayAndSunday;

    const eatDaysPerWeek = 5 + Number(saturday) + Number(sunday);

    switch (durationId) {
      case ProgramDurationsEnum.oneDay:
        return 1;

      case ProgramDurationsEnum.twoDays:
        return 2;

      case ProgramDurationsEnum.oneWeek:
        return eatDaysPerWeek;

      case ProgramDurationsEnum.twoWeeks:
        return 2 * eatDaysPerWeek;

      case ProgramDurationsEnum.threeWeeks:
        return 3 * eatDaysPerWeek;

      case ProgramDurationsEnum.fourWeeks:
        return 4 * eatDaysPerWeek;

      default:
        return 2;
    }
  }

  private getCheckedDailyCalories(dailyCalories: number, calorieOptions: number[]): number {
    if (calorieOptions.includes(dailyCalories)) {
      return dailyCalories;
    }

    return calorieOptions.reduce((prev, curr) => {
      return Math.abs(curr - dailyCalories) < Math.abs(prev - dailyCalories) ? curr : prev;
    });
  }

  private getCheckedDurationId(durationId: ProgramDurationsEnum, durationOptions: ProgramDurationInfo[]): ProgramDurationsEnum {
    if (durationOptions.find(item => item.id === ProgramDurationsEnum[durationId])) {
      return durationId;
    }

    return durationOptions[0].id;
  }

  private getCurrentDurationInfo(id: ProgramDurationsEnum, list: ProgramDurationInfo[]): ProgramDurationInfo {
    return list.find((item: ProgramDurationInfo) => item.id === id);
  }

  private getCheckedExtOptions(extOptions: ExtOptions, durationInfo: ProgramDurationInfo): ExtOptions {
    const newOptions: ExtOptions = {
      noBreakfastAndDinner: null,
      eatOnSaturday: null,
      eatOnSaturdayAndSunday: null,
      autoRenew: null,
    };

    if (!extOptions) {
      return newOptions;
    }

    Object.keys(extOptions).forEach(optionName => {
      const extOptionsInfo: ExtOptionsInfo = durationInfo?.extOptionsInfo || null;
      const hasOwnProperty: boolean = !!extOptionsInfo && extOptionsInfo.hasOwnProperty(optionName);
      const option: boolean = extOptions[optionName];
      const newOption = hasOwnProperty && option;

      newOptions[optionName] = newOption;
    });

    return newOptions;
  }

  public createNewProgram(previousProgram: CurrentProgram, programData: Partial<CurrentProgram>): CurrentProgram {
    const programInfo = getProgramViewAvailableData(programData.id || previousProgram?.id);

    const program = deepClone({
      ...previousProgram,
      ...programData,
      extOptions: {
        eatOnSaturday: false,
        eatOnSaturdayAndSunday: false,
        autoRenew: false,
        noBreakfastAndDinner: false,
        ...previousProgram?.extOptions,
        ...programData?.extOptions,
      },
    });

    const durationId = this.getCheckedDurationId(program.durationId, programInfo.durationOptions);
    const dailyCalories = this.getCheckedDailyCalories(program.dailyCalories, programInfo.calorieOptions);
    const currentDurationInfo = this.getCurrentDurationInfo(durationId, programInfo.durationOptions);
    const extOptions = this.getCheckedExtOptions(program.extOptions, currentDurationInfo);
    const defaultDelivery = currentDurationInfo.defaultDelivery || DeliveryOptionsEnum.empty;
    const durationInDays = this.getTotalDurationInDays(durationId, extOptions);

    return {
      ...program,
      durationId,
      dailyCalories,
      extOptions,
      defaultDelivery,
      durationInDays,
    };
  }

  /**
   * @returns Возвращает параметры для запроса доступных скидок по длительностям подписки
   */
  public getAvailableDiscountParams(
    { id, dailyCalories, mode, extOptions }: CurrentProgram,
    startDeliveryDay: string,
    durationOptions: ProgramDurationInfo[],
    promocodeFromInput: string,
    promocodeFromUrl: string,
  ): AvailableDiscountParams {
    const availableDiscountParams: AvailableDiscountParams = {
      size: dailyCalories,
      menuType: id,
      options: [],
      startDate: startDeliveryDay || this.subscriptionService.startOfDay(),
      mode,
      autoRenewal: extOptions.autoRenew,
    };

    durationOptions.forEach(duration => {
      const option: AvailableDiscountParamsOption = {
        daysCount: this.calculateDurationInDays(duration.id, extOptions),
        promocode: promocodeFromInput || promocodeFromUrl || null,
      };

      availableDiscountParams.options.push(option);
    });

    return availableDiscountParams;
  }

  /**
   * Метод считает кол-во дней подписки с учетом возможных доп. дней: субботы и/или воскресенья
   * @param durationId Продолжительность подписки
   * @example oneDay, twoDays, threeDays, oneWeek, twoWeeks, threeWeeks, fourWeeks
   * @param extOptions
   * @returns Количество дней питания
   */
  private calculateDurationInDays(durationId: ProgramDurationsEnum, { eatOnSaturday, eatOnSaturdayAndSunday }: ExtOptions): number {
    let eatDaysPerWeek = 5;

    if (eatOnSaturday && eatOnSaturdayAndSunday) {
      eatDaysPerWeek += 2;
    } else if (eatOnSaturdayAndSunday) {
      eatDaysPerWeek += 2;
    } else if (eatOnSaturday) {
      eatDaysPerWeek++;
    }

    switch (durationId) {
      case ProgramDurationsEnum.oneDay:
        return 1;

      case ProgramDurationsEnum.twoDays:
        return 2;

      case ProgramDurationsEnum.threeDays:
        return 3;

      case ProgramDurationsEnum.oneWeek:
        return eatDaysPerWeek;

      case ProgramDurationsEnum.twoWeeks:
        return 2 * eatDaysPerWeek;

      case ProgramDurationsEnum.threeWeeks:
        return 3 * eatDaysPerWeek;

      case ProgramDurationsEnum.fourWeeks:
        return 4 * eatDaysPerWeek;

      case ProgramDurationsEnum.twoMonth:
        return 8 * eatDaysPerWeek;

      default:
        return eatDaysPerWeek;
    }
  }

  public getPrice(
    program: CurrentProgram,
    promocode: string,
    patchState: (val: Partial<ProgramStateModel>) => void,
    availableRequestsCount = 2,
  ): Observable<SubscriptionPriceResponse> {
    /* Для того, чтобы исключить возможность бесконечной рекурсии! */
    if (!availableRequestsCount) {
      return EMPTY;
    }

    /* TODO: Переписать этот кусок после удаления вшитых промокодов!!! */
    return this.subscriptionService.fetchSubscriptionPrice(program, promocode).pipe(
      catchError(({ url, status, error }) => {
        if (promocode && url.includes(environment.url.subscriptionPrice)) {
          if (status === 422 || status === 400) {
            patchState({
              isPromocodeEnabled: false,
              promocodeErrorMessage: error?.error,
            });

            return this.getPrice(program, '', patchState, --availableRequestsCount);
          }
        }

        return EMPTY;
      }),
    );
  }
}
