/* eslint-disable-next-line max-classes-per-file */
import { ClientMenuDish, FeedbackDish } from '@modules/client-menu/models';
import { ProgramTypesEnum } from '@shared/enums';

enum ActionTypes {
  CLEAR_CLIENT_MENU_DATA = '[ClientMenu] Clear client menu data',

  SET_SUBSCRIPTIONS = '[ClientMenu] Set subscriptions',
  SET_ACTIVE_SUBSCRIPTION = '[ClientMenu] Set active subscription',
  SET_ACTIVE_PACKAGE = '[ClientMenu] Set active package',
  SET_DISHES_MAP = '[ClientMenu] Set dishes map',

  START_CUSTOMIZATION = '[ClientMenu] Start customization',
  DELETE_CUSTOMIZATION_BY_ID = '[ClientMenu] Delete customization by id',
  DELETE_ALL_CUSTOMIZATIONS = '[ClientMenu] Delete all customizations in subscription',

  LOAD_SUBSCRIPTION_DETAILS = '[ClientMenu] Load subscription details',
  LOAD_CLIENT_MENU = '[ClientMenu] Load client menu',

  CONFIRM_REPLACEMENT_SUCCESS = '[ClientMenu] Confirm replacement success',

  UPDATE_DISH_FEEDBACK = '[ClientMenu] Update dish feedback',

  DISABLE_PACKAGE_CUSTOMIZATION = '[ClientMenu] Disable package customization',

  SET_SUBSCRIPTION_DETAILS = '[ClientMenu] Set subscription details',

  LOAD_SUBSCRIPTIONS = '[ClientMenu] Load subscriptions',
  DELETE_SUBSCRIPTION = '[ClientMenu] Delete subscription',
  COMPLETE_UNPAID_SUBSCRIPTION = '[ClientMenu] Complete unpaid subscription',
  CANCEL_UNPAID_SUBSCRIPTION = '[ClientMenu] Cancel unpaid subscription',
  LOAD_ACTIVE_SUBSCRIPTION = '[ClientMenu] Load active subscription',
  LOAD_REPLACEMENT_DISHES = '[ClientMenu] Load replacement dishes',
  Load_Notifications = '[Notification] Load Notifications',
  Mark_As_Read = '[Notification] Mark As Read',
}

export class UpdateDishFeedback {
  static readonly type = ActionTypes.UPDATE_DISH_FEEDBACK;
  constructor(public dish: ClientMenuDish, public feedback: FeedbackDish) {}
}

export class ClearClientMenuData {
  static readonly type = ActionTypes.CLEAR_CLIENT_MENU_DATA;
}

export class LoadSubscriptions {
  static readonly type = ActionTypes.LOAD_SUBSCRIPTIONS;
}

export class SetActiveSubscription {
  static readonly type = ActionTypes.SET_ACTIVE_SUBSCRIPTION;
  constructor(public subscriptionId: string) {}
}

export class DeleteSubscription {
  static readonly type = ActionTypes.DELETE_SUBSCRIPTION;
  constructor(public subscriptionId: string) {}
}

export class CancelUnpaidSubscription {
  static readonly type = ActionTypes.CANCEL_UNPAID_SUBSCRIPTION;
  constructor(public subscriptionId: string) {}
}

export class CompleteUnpaidSubscription {
  static readonly type = ActionTypes.COMPLETE_UNPAID_SUBSCRIPTION;
  constructor(public subscriptionId: string) {}
}

export class ConfirmReplacementSuccess {
  static readonly type = ActionTypes.CONFIRM_REPLACEMENT_SUCCESS;
}

export class LoadActiveSubscription {
  static readonly type = ActionTypes.LOAD_ACTIVE_SUBSCRIPTION;
  constructor(
    public subscriptionId?: string,
    public options?: {
      /**
       * Дата активного дня подписки для календаря
       */
      date?: string;

      /**
       * Делать запрос деталей подписки даже если данные подписки уже есть (совпадает id активной подписки)
       */
      force?: boolean;

      /**
       * Тип подписки. Приходит из query
       * @example Sport, Fit, Life, Veg
       */
      menuType?: ProgramTypesEnum;

      /**
       * Длина подписки в неделях. Корректные варианты: 1, 2, 4. Приходит из query
       * @example 1, 2, 4
       */
      weeksLength?: '1' | '2' | '4';

      /**
       * Промокод. Приходит из query
       */
      promocode?: string;
    },
  ) {}
}

export class SetActivePackage {
  static readonly type = ActionTypes.SET_ACTIVE_PACKAGE;
  constructor(public date: string) {}
}

export class DisablePackageCustomization {
  static readonly type = ActionTypes.DISABLE_PACKAGE_CUSTOMIZATION;
  constructor(public subscriptionId: string, public packageId: string) {}
}

export class LoadReplacementDishes {
  static readonly type = ActionTypes.LOAD_REPLACEMENT_DISHES;
  constructor(public packageId: string, public dishId: string) {}
}

export class LoadNotifications {
  static readonly type = ActionTypes.Load_Notifications;
}

export class MarkAsRead {
  static readonly type = ActionTypes.Mark_As_Read;
  constructor(public id: string) {}
}
export class LoadSubscriptionDetails {
  static readonly type = ActionTypes.LOAD_SUBSCRIPTION_DETAILS;
  constructor(
    public subscriptionId?: string,
    public options?: {
      /**
       * Дата активного дня подписки для календаря
       */
      date?: string;

      /**
       * Делать запрос деталей подписки даже если данные подписки уже есть (совпадает id активной подписки)
       */
      force?: boolean;

      /**
       * Тип подписки. Приходит из query
       * @example Sport, Fit, Life, Veg
       */
      menuType?: ProgramTypesEnum;

      /**
       * Длина подписки в неделях. Корректные варианты: 1, 2, 4. Приходит из query
       * @example 1, 2, 4
       */
      weeksLength?: '1' | '2' | '4';

      /**
       * Промокод. Приходит из query
       */
      promocode?: string;
    },
  ) {}
}

export class LoadClientMenu {
  static readonly type = ActionTypes.LOAD_CLIENT_MENU;
  constructor(
    public options?: {
      /**
       * Нужно ли переключать активную подписку на первую из списка
       */
      setActiveSubscription?: boolean;

      /**
       * Чтобы исключить показа процесса загрузки
       */
      skipLoading?: boolean;
    },
  ) {}
}

export class StartCustomization {
  static readonly type = ActionTypes.START_CUSTOMIZATION;
  constructor(public packageId: string, public originalDishCode: string, public newDishCode: string) {}
}

export class DeleteCustomizationById {
  static readonly type = ActionTypes.DELETE_CUSTOMIZATION_BY_ID;
  constructor(public packageId: string, public customizationProgressItemId: string) {}
}

export class DeleteAllCustomizations {
  static readonly type = ActionTypes.DELETE_ALL_CUSTOMIZATIONS;
  constructor(public subscriptionId: string) {}
}
