<div class="container">
  <div class="inner-container">
    <div class="btn-back">
      <app-icon (click)="onGoBack()" class="icon-back" icon="toolbar-left"></app-icon>
    </div>

    <div class="title">{{ title }}</div>

    <div *ngIf="isUnpaidSubscription" class="btn-right">
      <ion-icon icon="ellipsis-horizontal" (click)="presentPopover($event)"></ion-icon>

      <ion-popover [mode]="'md'" #popover [isOpen]="isOpen" (didDismiss)="isOpen = false">
        <ng-template>
          <div class="popover ion-padding" (click)="handleRightButton()">
            <ion-icon name="close"></ion-icon>
            <span>Отменить подписку</span>
          </div>
        </ng-template>
      </ion-popover>
    </div>
  </div>
</div>
