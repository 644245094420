import { NavigationExtras } from '@angular/router';

/* eslint-disable max-classes-per-file */
enum ActionTypes {
  SET_AUTH = '[Auth] Set authorization status',
  SET_URL_AFTER_AUTH = '[Auth] Set url after auth',
  SEND_INPUT_PHONE = '[Auth] Set input phone',
  VERIFY_CODE = '[Auth] Verify code',
  INIT_AGREEMENTS = '[Auth] Init agreements',
  SET_PRIVACY_AGREEMENT = '[Auth] Set privacy agreement',
  SET_NOTIFICATIONS_AGREEMENT = '[Auth] Set notifications agreement',
}

export class SetAuth {
  static readonly type = ActionTypes.SET_AUTH;
  constructor(public isAuthorized: boolean) {}
}

export class SetUrlAfterAuth {
  static readonly type = ActionTypes.SET_URL_AFTER_AUTH;
  constructor(public commands: any[], public extras?: NavigationExtras) {}
}

export class SendInputPhone {
  static readonly type = ActionTypes.SEND_INPUT_PHONE;
  constructor(public inputPhone: string = '') {}
}

export class VerifyCode {
  static readonly type = ActionTypes.VERIFY_CODE;
  constructor(public code: string) {}
}

export class InitAgreements {
  static readonly type = ActionTypes.INIT_AGREEMENTS;
}

export class SetPrivacyAgreement {
  static readonly type = ActionTypes.SET_PRIVACY_AGREEMENT;
  constructor(public isChecked: boolean) {}
}

export class SetNotificationsAgreement {
  static readonly type = ActionTypes.SET_NOTIFICATIONS_AGREEMENT;
  constructor(public isChecked: boolean) {}
}
