export enum StorieIdEnum {
  FREE_REPLACEMENTS = 1,
  PERSONAL_DISCOUNTS = 2,
  INVITE_FRIENDS = 3,
  THANKS_PAGE = 4,
  BONUS_INFO = 5,
}

export enum SlideIdEnum {
  FREE_REPLACEMENT = 'freeReplacement',
  PERSONAL_DISCOUNT_1 = 'personalDiscount1',
  PERSONAL_DISCOUNT_2 = 'personalDiscount2',
  INVITE_FRIENDS = 'inviteFriends',
  THANKS_PAGE_DISCOUNT = 'thanksPageDiscount',
  BONUS_INFO = 'bonusInfo',
}
