import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AlertController, ToastController } from '@ionic/angular';
import { Device, DeviceInfo } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { Preferences } from '@capacitor/preferences';
import OneSignal, { OSNotification } from 'onesignal-cordova-plugin';
import { Action, State, StateContext, Store } from '@ngxs/store';

import { OneSignalInit, NotificationsStateModel, DeleteTokenBinding, SetupBindingToken, UpdateDeviceInfo } from '.';
import { BindTokenReqBody, DeleteBindTokenReqBody, SendEventReqBody } from '@shared/models';
import { BrowserLoadingScenarioService, PushService } from '@shared/services';
import { LocalStorageKeysEnum } from '@shared/enums';

import { environment } from 'src/environments/environment';

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults: {
    lastNotification: null,
    deviceInfo: null,
  },
})
@Injectable()
export class NotificationsState {
  isProd = environment.production;

  constructor(
    private store: Store,
    private router: Router,
    private alertController: AlertController,
    private toastController: ToastController,
    private pushService: PushService,
    private browserLoadingScenarioService: BrowserLoadingScenarioService,
  ) {}

  ngxsOnInit() {
    this.store.dispatch(new UpdateDeviceInfo());
  }

  @Action(UpdateDeviceInfo)
  async updateDeviceInfo({ patchState }: StateContext<NotificationsStateModel>): Promise<void> {
    const deviceInfo: DeviceInfo = await Device.getInfo();
    patchState({ deviceInfo });
  }

  @Action(OneSignalInit)
  async oneSignalInit({ patchState }: StateContext<NotificationsStateModel>): Promise<void> {
    const { platform } = await Device.getInfo();

    if (platform === 'web') {
      return;
    }

    OneSignal.initialize(environment.oneSignal.appID);
    OneSignal.User.pushSubscription.optIn();
    OneSignal.Notifications.addEventListener('click', async ({ notification }) => {
      this.sendPushEvent(notification);

      patchState({ lastNotification: notification });
      await Preferences.set({ key: LocalStorageKeysEnum.lastNotification, value: JSON.stringify(notification) });

      if (!this.isProd) {
        this.showModal('Получили пуш!', JSON.stringify(notification));
      }

      const url = notification?.additionalData?.['targetUrl'];

      if (url) {
        this.goToTargetUrl(url);
      }
    });

    OneSignal.Notifications.addEventListener('foregroundWillDisplay', async data => {
      const notification = data.getNotification();

      patchState({ lastNotification: notification });
      await Preferences.set({ key: LocalStorageKeysEnum.lastNotification, value: JSON.stringify(notification) });

      if (!this.isProd) {
        this.showToast('top', notification);
      }
    });
  }

  private async updateInterval(): Promise<void> {
    const newUpdate = Date.now();

    await Preferences.set({
      key: LocalStorageKeysEnum.lastUpdate,
      value: newUpdate.toString(),
    });
  }

  /**
   * В зависимости от того, включены ли пуш-уведомления, привязываем либо отвязываем токен
   */
  @Action(SetupBindingToken)
  async setupBindingToken({ dispatch, getState }: StateContext<NotificationsStateModel>): Promise<void> {
    const { deviceInfo } = getState();

    if (deviceInfo.platform === 'web') {
      return;
    }

    const permission = await PushNotifications.requestPermissions();
    const result = await Preferences.get({ key: LocalStorageKeysEnum.lastUpdate });
    const lastUpdate = result.value ? +result.value : 0;
    const interval = 1000 * 60 * 60 * 24; // День в миллисекундах
    const isTimeOut = Date.now() - lastUpdate > interval;

    if (permission.receive === 'granted') {
      if (!lastUpdate) {
        await this.bindToken();
        await this.updateInterval();

        return;
      } else if (!isTimeOut) {
        return;
      } else {
        await this.bindToken();
        await this.updateInterval();
      }
    }

    if (permission.receive === 'denied' && lastUpdate) {
      dispatch(new DeleteTokenBinding());
    }
  }

  private async bindToken(): Promise<void> {
    const pushToken = await OneSignal.User.pushSubscription.getTokenAsync();
    const playerId = await OneSignal.User.getOnesignalId();
    const deviceInfo: DeviceInfo = await Device.getInfo();

    const body: BindTokenReqBody = {
      playerId,
      pushToken,
      deviceModel: deviceInfo.model,
      osType: deviceInfo.platform === 'android' ? 'Android' : 'Ios',
      osVersion: deviceInfo.osVersion,
    };

    await firstValueFrom(this.pushService.bindToken(body));
  }

  @Action(DeleteTokenBinding)
  async deleteTokenBinding({ getState }: StateContext<NotificationsStateModel>): Promise<void> {
    const { deviceInfo } = getState();
    const platform = deviceInfo?.platform;

    if (platform === 'web') {
      return;
    }

    const resultLastUpdate = await Preferences.get({ key: LocalStorageKeysEnum.lastUpdate });

    if (!Boolean(resultLastUpdate.value)) {
      return;
    }

    const body: DeleteBindTokenReqBody = {
      playerId: await OneSignal.User.pushSubscription.getTokenAsync(),
      pushToken: await OneSignal.User.getOnesignalId(),
    };

    await firstValueFrom(this.pushService.deleteTokenBinding(body));
    await Preferences.remove({ key: LocalStorageKeysEnum.lastUpdate });
    await Preferences.removeOld();
  }

  openUrl(notification: OSNotification): void {
    const url = notification?.additionalData?.['targetUrl'];

    if (url) {
      this.goToTargetUrl(url);
    }
  }

  private goToTargetUrl(url: string): void {
    // targetUrl skip "justfood:/"
    const tempUrl = url.slice(11);
    const tempArrayUrl = tempUrl.split('?');
    const routeArray = tempArrayUrl[0].split('/');
    const queryParamsArray = tempArrayUrl[1].split('&').map(param => param.split('='));
    const queryParams = Object.fromEntries(queryParamsArray);

    this.showModal('targetUrl', JSON.stringify(routeArray) + '   ' + JSON.stringify(queryParams));

    this.router.navigate(routeArray, { queryParams }).then(() => {
      this.browserLoadingScenarioService.getPageLoadedAccess();
    });
  }

  private async showModal(header: string, message: string): Promise<void> {
    // Используем только на тестовых стендах!
    if (this.isProd) {
      return;
    }

    const alert = await this.alertController.create({
      header,
      subHeader: 'На проде такое окно не всплывает!',
      message,
      buttons: ['OK'],
      mode: 'ios',
    });

    await alert.present();
  }

  async showToast(position: 'top' | 'middle' | 'bottom', notification: OSNotification) {
    const toast = await this.toastController.create({
      header: notification.title,
      message: notification.body,
      duration: 10000,
      position: position,
      color: 'light',
      buttons: [
        {
          text: 'Открыть',
          role: 'info',
          handler: () => {
            this.sendPushEvent(notification);
            this.openUrl(notification);
          },
        },
        {
          text: 'Не сейчас',
          role: 'cancel',
          handler: () => {},
        },
      ],
      mode: 'ios',
      layout: 'stacked',
    });

    await toast.present();
  }

  private sendPushEvent({ title, additionalData, notificationId, androidNotificationId }: OSNotification): void {
    const sendEventReqBody: SendEventReqBody = {
      title: title,
      pushUrl: additionalData?.['targetUrl'],
      notificationId: notificationId,
      androidNotificationId: androidNotificationId?.toString() || '',
    };

    this.pushService.sendEvent(sendEventReqBody).subscribe(() => {
      this.showModal('Send event success!', JSON.stringify(sendEventReqBody));
    });
  }
}
