import { DeliveryIntervalResp } from '@shared/models';

export function getDeliveryTimeByInterval({ startHour, endHour, stepInMinutes, minIntervalInMinutes }: DeliveryIntervalResp): string[] {
  const result = [];
  let currentHour = startHour;
  const step = stepInMinutes / 60;
  const interval = minIntervalInMinutes / 60;

  while (currentHour + interval <= endHour) {
    const from = `${Math.floor(currentHour)}:${String(currentHour * 60 - Math.floor(currentHour) * 60).padStart(2, '0')}`;
    const to = `${Math.floor(currentHour + interval)}:${String(
      (currentHour + interval) * 60 - Math.floor(currentHour + interval) * 60,
    ).padStart(2, '0')}`;

    const timeString = 'С ' + from + ' до ' + to;

    result.push(timeString);
    currentHour += step;
  }

  return result;
}
