import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Clipboard } from '@capacitor/clipboard';
import { Store } from '@ngxs/store';
import Swiper from 'swiper';

import { StorySlide } from '../stories.model';
import { SlideIdEnum } from '../stories.enum';
import { ProfileData } from '@shared/models';
import { ProfileSelectors } from '@store/profile';

@Component({
  templateUrl: './story-viewer.component.html',
  styleUrls: ['./story-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryViewerComponent {
  @Input() public slides: StorySlide[];
  public slideIdEnum = SlideIdEnum;
  public profile: ProfileData = inject(Store).selectSnapshot(ProfileSelectors.profile);

  @ViewChild('progress') set progressElement(progress: any) {
    if (progress) {
      progress = progress.nativeElement;

      progress.addEventListener('animationend', () => {
        this.nextStoryItem();
      });

      progress.addEventListener('webkitAnimationEnd', () => {
        this.nextStoryItem();
      });
    }
  }

  @ViewChild('slider') swiperRef: ElementRef | undefined;

  get swiper(): Swiper {
    return this.swiperRef?.nativeElement.swiper;
  }

  public activeSlideIndex = 0;
  public isPaused = false;

  constructor(private modalCtrl: ModalController) {}

  public onChangeSlide(): void {
    this.activeSlideIndex = this.swiper.activeIndex;
  }

  public nextStoryItem(): void {
    if (this.activeSlideIndex < this.slides.length - 1) {
      this.activeSlideIndex++;
      this.swiper.slideNext();
    } else {
      this.closeStoryViewer(true);
    }
  }

  public async copyToClipboard(event: Event): Promise<void> {
    event.stopPropagation();
    await Clipboard.write({
      string: String(this.profile?.partnerInfo?.code),
    });
  }

  public closeStoryViewer(isShowed = false): void {
    this.modalCtrl.dismiss({ isShowed });
  }

  public pauseStory(): void {
    this.isPaused = true;
  }

  public playStory(): void {
    this.isPaused = false;
  }

  public clickButton(clickedSlide: StorySlide, event: Event): void {
    event.stopPropagation();
    this.modalCtrl.dismiss({ clickedSlide });
  }
}
