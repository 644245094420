<swiper-container #swiper [pagination]="true" [speed]="400" (swiperslidechange)="checkLastSlide()">
  <swiper-slide>
    <div class="image type-1"></div>

    <div class="content">
      <div class="container">
        <div class="title">justfood - это</div>
        <div class="text">Cвежие и вкусные рационы<br />с научным подходом. Привозим<br />вовремя и делаем питание выгодным</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Дальше</button>

          <a class="link-base link-skip" (click)="start()">Пропустить</a>
        </div>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="image type-2"></div>

    <div class="content">
      <div class="container">
        <div class="title">Быстрая доставка</div>
        <div class="text">Выбирайте удобное время — привезем с интервалом от 30 минут</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Отлично</button>

          <a class="link-base link-skip" (click)="start()">Пропустить</a>
        </div>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="image type-3"></div>

    <div class="content">
      <div class="container">
        <div class="title">Без лишних забот</div>
        <div class="text">Заменяйте блюда, ставьте рационы на паузу, переносите дни дальше</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Супер</button>

          <a class="link-base link-skip" (click)="start()">Пропустить</a>
        </div>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="image type-4"></div>

    <div class="content">
      <div class="container">
        <div class="title">Выгодные подписки</div>
        <div class="text">Чем дольше подписка, тем больше скидка. А еще можно платить частями — это удобно.</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Покажите еду</button>
        </div>
      </div>
    </div>
  </swiper-slide>
</swiper-container>
