import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';

import { STORIES_DATA } from '../stories-data.const';
import { Story, StorySlide } from '../stories.model';
import { StoryViewerComponent } from '../story-viewer/story-viewer.component';
import { SlideIdEnum } from '../stories.enum';
import { ToastService } from '@shared/services';

@Component({
  selector: 'app-stories',
  templateUrl: './stories-nav.component.html',
  styleUrls: ['./stories-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoriesComponent {
  public storiesData$ = new BehaviorSubject(STORIES_DATA);

  constructor(private modalCtrl: ModalController, private router: Router, private toastService: ToastService) {}

  public async openStoryViewer(story: Story): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: StoryViewerComponent,
      componentProps: { slides: story.slides },
    });

    modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.isShowed) {
      this.markShowedStory(story);
    }

    if (data.clickedSlide as StorySlide) {
      this.onClickSlide(data.clickedSlide);
    }
  }

  private markShowedStory(story: Story): void {
    Preferences.set({ key: 'stories_' + story.id, value: 'true' });

    const updatedData = STORIES_DATA.map(item =>
      item.id !== story.id
        ? item
        : {
            ...item,
            isShowed: true,
          },
    );

    this.storiesData$.next(updatedData);
  }

  private onClickSlide(slide: StorySlide): void {
    // Для слайда с оформлением подписки нужно показать всплывашку
    if (slide.id === SlideIdEnum.PERSONAL_DISCOUNT_1) {
      if (slide.buttonText === 'Оформить подписку') {
        this.toastService.presentToastSuccess('bottom', 'Выберите подписку для продления');
        return;
      }
      return;
    }

    const url = slide.buttonLink;

    if (!url) {
      return;
    }

    url.includes('http') ? (document.location.href = url) : this.router.navigateByUrl(url);
  }
}
