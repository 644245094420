import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Router } from '@angular/router';
import Swiper from 'swiper';

import { LocalStorageKeysEnum, CommonEvents } from '@shared/enums';
import { RudderStackService } from '@shared/services';

@Component({
  selector: 'app-intro-container',
  templateUrl: './intro-container.component.html',
  styleUrls: ['./intro-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntroContainerComponent implements OnInit {
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;

  get swiper(): Swiper {
    return this.swiperRef?.nativeElement.swiper;
  }

  isLastSlide: boolean;

  constructor(private router: Router, private cdr: ChangeDetectorRef, private rudderstack: RudderStackService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.rudderstack.track(CommonEvents.showIntro);
    }, 1500);
  }

  next() {
    const activeIndex = this.swiper.activeIndex;

    if (activeIndex === 3) {
      this.start();
    } else {
      this.swiper.slideNext();
    }
  }

  async start() {
    await Preferences.set({ key: LocalStorageKeysEnum.introSeen, value: 'true' });
    this.router.navigate(['/login']);
  }

  checkLastSlide() {
    const activeIndex = this.swiper.activeIndex;

    this.isLastSlide = activeIndex === 2;
    this.cdr.markForCheck();
  }
}
