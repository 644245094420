import { ClientMenuDish } from '@modules/client-menu/models';
import { Selector } from '@ngxs/store';

import { ClientSubscription, ClientSubscriptionDetails, ClientSubscriptionPackage, Notification } from '@shared/models';
import { ClientMenuStateModel } from './client-menu.model';

export class ClientMenuSelectors {
  @Selector([state => state.clientMenu])
  static state(state: ClientMenuStateModel): ClientMenuStateModel {
    return state;
  }

  @Selector([ClientMenuSelectors.state])
  static subscriptionsList(state: ClientMenuStateModel): ClientSubscription[] {
    return Object.values(state.subscriptionList);
  }

  @Selector([ClientMenuSelectors.state])
  static subscriptionDetails(state: ClientMenuStateModel): ClientSubscriptionDetails {
    return state.activeSubscriptionDetails;
  }

  @Selector([ClientMenuSelectors.state])
  static activePackage({ activePackage }: ClientMenuStateModel): ClientSubscriptionPackage {
    return activePackage;
  }

  @Selector([ClientMenuSelectors.state])
  static activePackageId({ activePackage }: ClientMenuStateModel): string {
    return activePackage?.packageId;
  }

  @Selector([ClientMenuSelectors.state])
  static unfinishedPaymentId({ activeSubscriptionDetails }: ClientMenuStateModel): string {
    if (!activeSubscriptionDetails) {
      return null;
    }

    return activeSubscriptionDetails?.unfinishedPaymentId;
  }

  @Selector([ClientMenuSelectors.state])
  static replacementDates({ activeSubscriptionDetails }: ClientMenuStateModel): string[] {
    return activeSubscriptionDetails?.packageItems
      .filter(({ customizationBasket }) => Boolean(customizationBasket))
      .map(({ date }) => date);
  }

  @Selector([ClientMenuSelectors.state])
  static replacementsDishes({ replacementDishes }: ClientMenuStateModel): ClientMenuDish[] {
    return replacementDishes;
  }

  @Selector([ClientMenuSelectors.state])
  static sameTypeDishes({ replacementDishes }: ClientMenuStateModel): ClientMenuDish[] {
    return replacementDishes.filter(dish => dish.isSameMenuType);
  }

  @Selector([ClientMenuSelectors.state])
  static otherDishes({ replacementDishes }: ClientMenuStateModel): ClientMenuDish[] {
    return replacementDishes.filter(dish => !dish.isSameMenuType);
  }

  @Selector([ClientMenuSelectors.state])
  static customizationsCount({ activeSubscriptionDetails }: ClientMenuStateModel): number {
    const count = activeSubscriptionDetails?.packageItems.reduce((acc, curr) => acc + (curr?.customizationBasket?.items.length || 0), 0);

    return count;
  }

  @Selector([ClientMenuSelectors.state])
  static additionsCount({ activeSubscriptionDetails }: ClientMenuStateModel): number {
    const count = activeSubscriptionDetails?.packageItems.reduce(
      (acc, curr) => acc + (curr?.customizationBasket?.items.filter(({ originalDish }) => !originalDish).length || 0),
      0,
    );

    return count;
  }

  @Selector([ClientMenuSelectors.state])
  static customizationPrice({ activeSubscriptionDetails }: ClientMenuStateModel): number {
    const price =
      activeSubscriptionDetails?.packageItems.reduce((acc, curr) => acc + (curr?.customizationBasket?.priceIncrease || 0), 0) || 0;

    return price;
  }

  @Selector([ClientMenuSelectors.state])
  static changesInClientMenu({ activeSubscriptionDetails }: ClientMenuStateModel): ClientMenuDish[] {
    return activeSubscriptionDetails?.packageItems
      .flatMap(({ customizationBasket }) => customizationBasket?.items)
      .filter(Boolean)
      .flatMap(({ newDish, priceIncrease }) => ({ ...newDish, priceIncrease }));
  }

  @Selector([ClientMenuSelectors.state])
  static getNotifications({ notifications }: ClientMenuStateModel): Notification[] {
    return notifications;
  }
}
