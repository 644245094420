export enum LoadingTagEnum {
  updatingProgram = 'updatingProgram',
  updatingProgramPrice = 'updatingProgramPrice',
  ingredientsExclusion = 'ingredientsExclusion',
  checkingPromocode = 'checkingPromocode',
  creatingSubscription = 'creatingSubscription',
  loadSubscriptionInfo = 'loadSubscriptionInfo',
  renewSubscriptionInfo = 'renewSubscriptionInfo',
  loadNotifications = 'loadNotifications',
  didClientMenuPageFail = 'didClientMenuPageFail',
  isClientMenuLoading = 'isClientMenuLoading',
  isSubscriptionDetailsLoading = 'isSubscriptionDetailsLoading',
  isSubscriptionListLoading = 'isSubscriptionListLoading',
}
