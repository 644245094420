import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ClientMenuDish, ReplacementInSubscription, ConfirmReplacementResponse, CustomizationProgressBody, Subscription } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ClientMenuApiService {
  constructor(private http: HttpClient) {}

  /**
   * Информация о меню клиента в текущих подписок (и justfood, и prostoeda)
   */
  public getUserSubscriptions(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(environment.url.clientMenu);
  }

  public getReplacementDishes(packageId: string, dishId: string): Observable<ClientMenuDish[]> {
    return this.http.get<ClientMenuDish[]>(environment.url.subscription + `/${packageId}/dishes-for-replacement/${dishId}`);
  }

  /**
   * Выполнить кастомизацию блюд в подписке
   */
  public confirmCustomization(subscriptionId: string, data: ReplacementInSubscription): Observable<ConfirmReplacementResponse> {
    return this.http.post<ConfirmReplacementResponse>(environment.url.subscription + `/${subscriptionId}/confirm-customization`, data);
  }

  /**
   * Добавить элемент в корзину кастомизаций
   */
  public customizationProgress(packageId: string, body: CustomizationProgressBody): Observable<void> {
    return this.http.post<void>(environment.url.packages + `/${packageId}/customization-progress`, body);
  }

  /**
   * Удалить элемент из корзины кастомизаций пакета
   */
  public cancelCustomizationProgress(packageId: string, dishId: string): Observable<void> {
    return this.http.delete<void>(environment.url.packages + `/${packageId}/customization-progress/${dishId}`);
  }

  /**
   * Удаление корзин кастомизаций во всех пакетах подписки
   */
  public cancelAllCustomizationProgress(subscriptionId: string): Observable<void> {
    return this.http.delete<void>(environment.url.subscription + `/${subscriptionId}/customization-progress`);
  }

  /**
   * Отменить неоплаченую кастомизацию
   */
  public cancelCustomization(paymentId: string): Observable<any> {
    return this.http.post<any>(environment.url.subscription + `/${paymentId}/cancel-customization`, {});
  }

  public checkReplacementAvailability(): Observable<boolean> {
    return of(false); // this.http.get<boolean>(environment.url.isReplacementAvailable);
  }

  public getAdditionalDishes(packageId: string): Observable<ClientMenuDish[]> {
    return this.http.get<ClientMenuDish[]>(environment.url.subscription + `/${packageId}/additional-dishes`);
  }
}
