import { FeedbackDishRequest, FeedbackToSend } from '@modules/client-menu/models';

enum ActionTypes {
  GENERATE_MENU_FEEDBACK = '[Feedback] Generate menu feedback',
  GENERATE_DISH_FEEDBACK = '[Feedback] Generate dish feedback',
  GENERATE_SUBSCRIPTION_FEEDBACK = '[Feedback] Generate subscription feedback',
  GENERATE_SUBSCRIPTION_CREATION_OR_PROLONGATION_FEEDBACK = '[Feedback] Generate subscription creation or prolongation feedback',
  GENERATE_CUSTOMIZATION_FEEDBACK = '[Feedback] Generate customization feedback',
  GENERATE_DELIVERY_FEEDBACK = '[Feedback] Generate delivery feedback',

  SEND_ANSWERS = '[Feedback] Send answers',
  CLOSE_MODAL = '[Feedback] Close modal',
}

export class GenerateMenuFeedback {
  static readonly type = ActionTypes.GENERATE_MENU_FEEDBACK;
}

export class GenerateDishFeedback {
  static readonly type = ActionTypes.GENERATE_DISH_FEEDBACK;
  constructor(public data: FeedbackDishRequest) {}
}

export class GenerateSubscriptionFeedback {
  static readonly type = ActionTypes.GENERATE_SUBSCRIPTION_FEEDBACK;
}

export class GenerateSubscriptionCreationOrProlongationFeedback {
  static readonly type = ActionTypes.GENERATE_SUBSCRIPTION_CREATION_OR_PROLONGATION_FEEDBACK;
  constructor(public subscriptionId: string) {}
}

export class GenerateCustomizationFeedback {
  static readonly type = ActionTypes.GENERATE_CUSTOMIZATION_FEEDBACK;
}

export class GenerateDeliveryFeedback {
  static readonly type = ActionTypes.GENERATE_DELIVERY_FEEDBACK;
}

export class SendAnswers {
  static readonly type = ActionTypes.SEND_ANSWERS;
  constructor(public feedbackId: string, public feedbackToSend: FeedbackToSend) {}
}

export class CloseModal {
  static readonly type = ActionTypes.CLOSE_MODAL;
}
