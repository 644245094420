import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { RudderStackService } from '@shared/services';
import { CommonEvents } from '@shared/enums';

@Component({
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginContainerComponent {
  constructor(private router: Router, private rudderstack: RudderStackService) {}

  goToPhoneScreen(): void {
    this.rudderstack.track(CommonEvents.showLoginPhone);
    this.router.navigate(['login']);
  }
}
