import { Selector } from '@ngxs/store';

import {
  AvailableDiscount,
  AvailableMovingDays,
  BonusAccount,
  CurrentProgram,
  DeliveryIntervalResp,
  ExtOptions,
  SubscriptionInfoForThanksPage,
  SubscriptionPriceResponse,
} from '@shared/models';
import { ProgramStateModel } from './program.model';
import { ProgramCaloriesEnum, ProgramDurationsEnum, ProgramModesEnum, ProgramTypesEnum } from '@shared/enums';

export class ProgramSelectors {
  @Selector([state => state.program])
  static state(state: ProgramStateModel): ProgramStateModel {
    return state;
  }

  @Selector([ProgramSelectors.state])
  static program(state: ProgramStateModel): CurrentProgram {
    return state?.program;
  }

  @Selector([ProgramSelectors.state])
  static extOptions(state: ProgramStateModel): ExtOptions {
    return state.program.extOptions;
  }

  @Selector([ProgramSelectors.state])
  static promocode(state: ProgramStateModel): string {
    return state.promocodeFromInput || state.promocodeFromUrl || '';
  }

  @Selector([ProgramSelectors.state])
  static isMode(state: ProgramStateModel): boolean {
    return state?.program.id === ProgramTypesEnum.Sport && state?.program.mode === ProgramModesEnum.ExperimentalWeek;
  }

  @Selector([ProgramSelectors.state])
  static availableDiscounts(state: ProgramStateModel): AvailableDiscount[] {
    return state?.availableDiscounts;
  }

  @Selector([ProgramSelectors.state])
  static startDeliveryDay(state: ProgramStateModel): string {
    return state?.startDeliveryDay;
  }

  @Selector([ProgramSelectors.state])
  static availableDates(state: ProgramStateModel): string[] {
    return state?.availableDates;
  }

  @Selector([ProgramSelectors.state])
  static promocodeErrorMessage(state: ProgramStateModel): string {
    return state?.promocodeErrorMessage;
  }

  @Selector([ProgramSelectors.state])
  static deliveryIntervalInfo(state: ProgramStateModel): DeliveryIntervalResp {
    return state?.deliveryIntervalInfo;
  }

  @Selector([ProgramSelectors.state])
  static calendarAvailableMovingDays(state: ProgramStateModel): AvailableMovingDays {
    return state.calendarAvailableMovingDays;
  }

  @Selector([ProgramSelectors.state])
  static isLoadingMovingDays(state: ProgramStateModel): boolean {
    return state.isLoadingMovingDays;
  }

  @Selector([ProgramSelectors.program])
  static isRenewal(program: CurrentProgram): boolean {
    return program?.extOptions?.autoRenew || false;
  }

  @Selector([ProgramSelectors.program])
  static isSaturday(program: CurrentProgram): boolean {
    return program?.extOptions?.eatOnSaturday || false;
  }

  @Selector([ProgramSelectors.program])
  static isWholeWeekend(program: CurrentProgram): boolean {
    return program?.extOptions?.eatOnSaturdayAndSunday || false;
  }

  @Selector([ProgramSelectors.program])
  static isNoBreakfastAndDinner(program: CurrentProgram): boolean {
    return program?.extOptions?.noBreakfastAndDinner || false;
  }

  @Selector([ProgramSelectors.state])
  static isShowNoBreakfastAndDinnerToggle({ program }: ProgramStateModel): boolean {
    // Тоггл 'Без завтраков и ужинов' должен быть только у типа 'Разнообразное'
    // за исключением калорийностей 1200 и 1500 и продолжительности 2 дня!
    return (
      program.id === ProgramTypesEnum.Fit &&
      ![ProgramCaloriesEnum.cal1200, ProgramCaloriesEnum.cal1500].includes(program.dailyCalories) &&
      program.durationId !== ProgramDurationsEnum.twoDays
    );
  }

  @Selector([ProgramSelectors.program])
  static durationInDays(program: CurrentProgram): number {
    return program.durationInDays;
  }

  @Selector([ProgramSelectors.state])
  static priceData(state: ProgramStateModel): SubscriptionPriceResponse {
    return state.priceData;
  }

  @Selector([ProgramSelectors.state])
  static allowBonuses(state: ProgramStateModel): number {
    return state.bonusAccount?.balance || 0;
  }

  @Selector([ProgramSelectors.state])
  static bonusAccount(state: ProgramStateModel): BonusAccount {
    return state.bonusAccount;
  }

  @Selector([ProgramSelectors.state])
  static subscriptionInfo(state: ProgramStateModel): SubscriptionInfoForThanksPage {
    return state.subscriptionInfo;
  }
}
