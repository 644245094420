import { Injectable } from '@angular/core';
import { debounceTime, filter, Observable, tap } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';

import { FeedbackStateModel } from './feedback.model';
import {
  CloseModal,
  GenerateCustomizationFeedback,
  GenerateDeliveryFeedback,
  GenerateDishFeedback,
  GenerateMenuFeedback,
  GenerateSubscriptionCreationOrProlongationFeedback,
  GenerateSubscriptionFeedback,
  SendAnswers,
} from './feedback.actions';
import { FeedbackApiService } from '@modules/client-menu/services';
import { Feedback } from '@modules/client-menu/models';
import { ToastService } from '@shared/services';

@State<FeedbackStateModel>({
  name: 'feedback',
  defaults: {
    feedback: null,
    isFeedbackSent: false,
    isFeedbackModalOpened: false,
  },
})
@Injectable()
export class FeedbackState {
  constructor(private feedbackApiService: FeedbackApiService, private toastService: ToastService) {}

  @Action(GenerateMenuFeedback)
  generateMenuFeedback({ patchState }: StateContext<FeedbackStateModel>): Observable<Feedback> {
    return this.feedbackApiService.generateMenuFeedback().pipe(
      tap(v => this.toastService.debugToastWarning('Отправлен запрос фидбека', 'generateMenuFeedback, ответ: ' + JSON.stringify(v))),
      filter(Boolean),
      tap(feedback => patchState({ feedback, isFeedbackModalOpened: true })),
    );
  }

  @Action(GenerateDishFeedback)
  generateDishFeedback({ patchState }: StateContext<FeedbackStateModel>, { data }: GenerateDishFeedback): Observable<Feedback> {
    return this.feedbackApiService.generateDishFeedback(data).pipe(
      tap(v => this.toastService.debugToastWarning('Отправлен запрос фидбека', 'generateDishFeedback, ответ: ' + JSON.stringify(v))),
      filter(Boolean),
      tap(feedback => patchState({ feedback, isFeedbackModalOpened: true })),
    );
  }

  @Action(GenerateSubscriptionFeedback)
  generateSubscriptionFeedback({ patchState }: StateContext<FeedbackStateModel>): Observable<Feedback> {
    return this.feedbackApiService.generateSubscriptionFeedback().pipe(
      tap(v =>
        this.toastService.debugToastWarning('Отправлен запрос фидбека', 'generateSubscriptionFeedback, ответ: ' + JSON.stringify(v)),
      ),
      filter(Boolean),
      tap(feedback => patchState({ feedback, isFeedbackModalOpened: true })),
    );
  }

  @Action(GenerateSubscriptionCreationOrProlongationFeedback)
  generateSubscriptionCreationOrProlongationFeedback(
    { patchState }: StateContext<FeedbackStateModel>,
    { subscriptionId }: GenerateSubscriptionCreationOrProlongationFeedback,
  ): Observable<Feedback> {
    return this.feedbackApiService.generateSubscriptionCreationOrProlongationFeedback(subscriptionId).pipe(
      tap(v =>
        this.toastService.debugToastWarning(
          'Отправлен запрос фидбека',
          'generateSubscriptionCreationOrProlongationFeedback, ответ: ' + JSON.stringify(v),
        ),
      ),
      filter(Boolean),
      tap(feedback => patchState({ feedback, isFeedbackModalOpened: true })),
    );
  }

  @Action(GenerateCustomizationFeedback)
  generateCustomizationFeedback({ patchState }: StateContext<FeedbackStateModel>): Observable<Feedback> {
    return this.feedbackApiService.generateCustomizationFeedback().pipe(
      tap(v =>
        this.toastService.debugToastWarning('Отправлен запрос фидбека', 'generateCustomizationFeedback, ответ: ' + JSON.stringify(v)),
      ),
      filter(Boolean),
      tap(feedback => patchState({ feedback, isFeedbackModalOpened: true })),
    );
  }

  @Action(GenerateDeliveryFeedback)
  generateDeliveryFeedback({ patchState }: StateContext<FeedbackStateModel>): Observable<Feedback> {
    return this.feedbackApiService.generateDeliveryFeedback().pipe(
      tap(v => this.toastService.debugToastWarning('Отправлен запрос фидбека', 'generateDeliveryFeedback, ответ: ' + JSON.stringify(v))),
      filter(Boolean),
      tap(feedback => patchState({ feedback, isFeedbackModalOpened: true })),
    );
  }

  @Action(SendAnswers)
  sendAnswers({ patchState }: StateContext<FeedbackStateModel>, { feedbackId, feedbackToSend }: SendAnswers): Observable<void> {
    return this.feedbackApiService
      .sendAnswers(feedbackId, feedbackToSend)
      .pipe(tap(() => patchState({ feedback: null, isFeedbackSent: true, isFeedbackModalOpened: false })));
  }

  @Action(CloseModal)
  closeModal({ patchState }: StateContext<FeedbackStateModel>): void {
    patchState({ isFeedbackModalOpened: false });
  }
}
