/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';

import { PayCryptogramResponse, PaymentInfo, RenewedSubscriptionData } from '@shared/models';

enum ActionTypes {
  SET_STARTED_PAYMENT = '[Payment] Set started payment',
  CLEAR_STARTED_PAYMENT = '[Payment] Clear started payment',

  SET_PAYMENT_INFO = '[Payment] Set payment info',
  SET_PAYMENT_RESPONSE = '[Payment] Set apple pay/google pay response',
  SET_PAYMENT_ERROR_RESPONSE = '[Payment] Set apple pay/google pay error response',
  SET_CREATED_RENEWED_SUBSCRIPTION = '[Payment] Set created renewed subscription',
  SET_PAYMENT_STEP = '[Payment] Set payment step',
  LOAD_CARDS = '[Payment] Load cards',
  LOAD_PAYMENT_DETAILS = '[Payment] Load payment details',
  CLEAR_PAYMENT_DETAILS = '[Payment] Clear payment details',
  SET_ACTIVE_CARD = '[Payment] Set active card',
  PAY_BY_NEW_CARD = '[Payment] Pay by new card',
  PAY_BY_SAVED_CARD = '[Payment] Pay by saved card',
  PAY_BY_SBP = '[Payment] Pay by SBP',
  LISTEN_PAYMENT_SBP_STATUS = '[Payment] Get payment sbp status',
  STOP_LISTEN_PAYMENT_SBP_STATUS = '[Payment] Stop listen payment sbp status',
  SET_THANKS_PAGE_TEXT = '[Payment] Set thanks page text',
}

export class SetPaymentInfo {
  static readonly type = ActionTypes.SET_PAYMENT_INFO;
  constructor(public paymentInfo: PaymentInfo) {}
}

export class SetPaymentSystemResp {
  static readonly type = ActionTypes.SET_PAYMENT_RESPONSE;
  constructor(public paymentSystemResp: PayCryptogramResponse) {}
}

export class SetPaymentSystemErrorResp {
  static readonly type = ActionTypes.SET_PAYMENT_ERROR_RESPONSE;
  constructor(public paymentSystemErrorResp: HttpErrorResponse) {}
}

export class SetStartedPayment {
  static readonly type = ActionTypes.SET_STARTED_PAYMENT;
  constructor(public paymentId: string, public shouldSaveToLocalStorage: boolean = true) {}
}

export class SetCreatedRenewedSubscription {
  static readonly type = ActionTypes.SET_CREATED_RENEWED_SUBSCRIPTION;
  constructor(public createdRenewedSubscription: RenewedSubscriptionData) {}
}

export class ClearStartedPayment {
  static readonly type = ActionTypes.CLEAR_STARTED_PAYMENT;
}

export class SetPaymentStep {
  static readonly type = ActionTypes.SET_PAYMENT_STEP;
  constructor(public paymentStep: number) {}
}

export class LoadCards {
  static readonly type = ActionTypes.LOAD_CARDS;
  constructor(public paymentId: string) {}
}

export class LoadPaymentDetails {
  static readonly type = ActionTypes.LOAD_PAYMENT_DETAILS;
  constructor(public paymentId: string) {}
}

export class ClearPaymentDetails {
  static readonly type = ActionTypes.CLEAR_PAYMENT_DETAILS;
}

export class SetActiveCard {
  static readonly type = ActionTypes.SET_ACTIVE_CARD;
  constructor(public activeCardId: string) {}
}

export class PayByNewCard {
  static readonly type = ActionTypes.PAY_BY_NEW_CARD;
  constructor(public clientId: string) {}
}

export class PayBySavedCard {
  static readonly type = ActionTypes.PAY_BY_SAVED_CARD;
  constructor(public paymentGuid: string, public cardId: string) {}
}

export class PayBySbp {
  static readonly type = ActionTypes.PAY_BY_SBP;
  constructor(public paymentId: string) {}
}

export class ListenPaymentSbpStatus {
  static readonly type = ActionTypes.LISTEN_PAYMENT_SBP_STATUS;
  constructor(public paymentId: string) {}
}

export class StopListenPaymentSbpStatus {
  static readonly type = ActionTypes.STOP_LISTEN_PAYMENT_SBP_STATUS;
}

export class SetThanksPageText {
  static readonly type = ActionTypes.SET_THANKS_PAGE_TEXT;
  constructor(public title: string, public text?: string, public btnText?: string) {}
}
