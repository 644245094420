import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ConfirmReplacementResponse, ReplacementInSubscription } from '@modules/client-menu/models';
import { ClientMenuApiService } from '@modules/client-menu/services';

@Injectable({
  providedIn: 'root',
})
export class ClientMenuActionsService {
  constructor(private store: Store, private apiService: ClientMenuApiService) {}

  /**
   * Подтверждение всех кастомизаций в подписке
   * @param activeSubscription
   * @param skipCustomizationErrors
   * @returns
   */
  public confirmCustomization(subscriptionId: string, skipCustomizationErrors = false): Observable<ConfirmReplacementResponse> {
    const requestData: ReplacementInSubscription = {
      skipCustomizationErrors,
    };

    return this.apiService.confirmCustomization(subscriptionId, requestData);
  }
}
