import { ClientSubscriptionDetails, ClientSubscriptionPackage, DayItem } from '@shared/models';
import dayjs, { Dayjs } from 'dayjs';
import { capitalize } from './capitalize.util';
import { getRuDay } from './dates.util';

export function getDayItem(date: Dayjs, subscription: ClientSubscriptionDetails): DayItem {
  const packageInfo = getPackageInfo(date, subscription);

  return {
    date,
    dateString: date.toISOString(),
    displayDate: date.format('D'),
    displayDayWeek: capitalize(getRuDay(date)),
    isFeedDate: !!packageInfo,
    isDeliveryDate: packageInfo?.isDeliveryDate,
    packageState: packageInfo?.packageState,
    packageId: packageInfo?.packageId,
  };
}

function getPackageInfo(date: Dayjs, subscription: ClientSubscriptionDetails): ClientSubscriptionPackage {
  const formattedDate = date.format('DD/MM/YYYY');

  return subscription?.packageItems.find((item: ClientSubscriptionPackage) => {
    return dayjs(item.date).format('DD/MM/YYYY') === formattedDate;
  });
}
