import { ChangeDetectionStrategy, Component, Input, OnInit, Self } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs';
import { Store } from '@ngxs/store';

import { ImageFileTypeEnum, ImageTypeEnum, RuDishTypesEnum, SubscriptionStatusEnum } from '@shared/enums';
import { DishImagePipe } from '@shared/pipes';
import { NgOnDestroyService } from '@shared/services';
import { ClientSubscriptionPackage, MenuItem } from '@shared/models';
import { FeedbackDishService } from '@modules/client-menu/services';
import { ClientMenuDish } from '@modules/client-menu/models';
import { DeleteCustomizationById } from '@store/client-menu';

@Component({
  selector: 'app-dish-details',
  templateUrl: './dish-details.component.html',
  styleUrls: ['./dish-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService, DishImagePipe],
})
export class DishDetailsComponent implements OnInit {
  @Input() dish: ClientMenuDish;
  @Input() menuExampleDish: MenuItem;
  @Input() package: ClientSubscriptionPackage;
  @Input() isFromAdditionModal: boolean;
  @Input() isFromMenuExample: boolean;
  @Input() isFromSelectDishModal: boolean = false;
  @Input() subscriptionStatus = SubscriptionStatusEnum.Active;
  public readonly ImageTypeEnum = ImageTypeEnum;
  public readonly ImageFileTypeEnum = ImageFileTypeEnum;
  public readonly ruDishTypeEnum = RuDishTypesEnum;
  public content: any;
  public menuExampleContent: any;

  constructor(
    @Self() private ngOnDestroy$: NgOnDestroyService,
    private store: Store,
    private modalCtrl: ModalController,
    private feedbackDishService: FeedbackDishService,
    private dishImagePipe: DishImagePipe,
  ) {}

  ngOnInit(): void {
    this.content = (this.dish?.ingredients || '').split(',').join(', ');
    this.menuExampleContent = (this.menuExampleDish?.ingredients || '').split(',').join(', ');
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  public setDishRating(dish: ClientMenuDish, userPackage: ClientSubscriptionPackage): void {
    this.feedbackDishService
      .openDialog({
        dish,
        packageId: userPackage.packageId,
        date: userPackage.date,
      })
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe();
  }

  public deselect(dish: ClientMenuDish): void {
    this.store.dispatch(new DeleteCustomizationById(this.package.packageId, dish.customizationId));
    this.closeModal();
  }

  public isArray(property: any): boolean {
    return Array.isArray(property);
  }

  public getImageSources(dishCode: string, imageType: ImageTypeEnum, fileType: ImageFileTypeEnum): string {
    if (!dishCode) {
      return null;
    }

    const version = '?v=1';
    const imageUrl = this.dishImagePipe.transform(dishCode, imageType, fileType) + version;

    return `${imageUrl}`;
  }

  public getImageSourcesRetina(dishCode: string, imageType: ImageTypeEnum, fileType: ImageFileTypeEnum): string {
    const version = '?v=1';

    return this.dishImagePipe.transform(dishCode, imageType, fileType, true) + version + ' 2x';
  }
}
