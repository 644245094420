<ng-container *ngIf="!isFeedbackSent; else thanks">
  <div class="title">{{ feedback?.feedbackName }}</div>

  <div class="rating">
    <app-rating
      [value]="rating"
      [size]="'large'"
      (changeValue)="chooseRating($event, feedback)"
      id="dish-feedback-rating"
      class="rating-widget"
    ></app-rating>
  </div>

  <ng-container *ngIf="question; else closeBtn">
    <div class="feedback">
      <div class="label">{{ question }}</div>

      <div class="answers">
        <div *ngFor="let answer of answers" class="answer" [class.choosen]="choosenAnswers.has(answer)" (click)="setChoosenAnswers(answer)">
          {{ answer }}
        </div>
      </div>
    </div>

    <div class="comment">
      <textarea
        [placeholder]="rating <= 3 ? 'Расскажите что не понравилось. Проведём работу над ошибками' : 'Расскажите что мы можем сделать лучше. Мы будем работать над собой'"
        class="comment-textarea"
        [(ngModel)]="comment"
      ></textarea>
    </div>

    <button class="btn btn-base btn-send" (click)="onCompleteFeedback()">Оставить оценку</button>
  </ng-container>

  <ng-template #closeBtn>
    <button class="btn btn-base btn-send" (click)="closeModal()">Закрыть</button>
  </ng-template>
</ng-container>

<ng-template #thanks>
  <div class="title">Спасибо за отзыв</div>
  <div class="text-success">Отправили его в отдел контроля качества. С вашей помощью мы становимся лучше</div>

  <img class="star" src="/assets/images/star-large.png" />

  <div class="banner" (click)="showBanner()">
    <p class="text">
      Так же можете получить<br />
      <span>- 5%</span> за отзыв в соцсети
    </p>

    <p class="link">Подробнее</p>
  </div>

  <button class="btn btn-base btn-send" (click)="closeModal()">Продолжить</button>
</ng-template>
