<div
  class="wrapper"
  (touchstart)="pauseStory()"
  (touchend)="playStory()"
  (press)="pauseStory()"
  (pressup)="playStory()"
  (panstart)="pauseStory()"
  (panend)="playStory()"
>
  <div class="top-panel">
    <div class="progress-bar">
      <ng-container *ngFor="let slide of slides; let index = index">
        <div class="progress-item" [class.isPaused]="isPaused" [class.black]="slide?.closeTheme === 'dark'">
          <div *ngIf="activeSlideIndex === index" class="active" #progress></div>
        </div>
      </ng-container>
    </div>
    <ion-icon
      [class]="slides[0]?.closeTheme === 'dark' ? 'black-close' : 'close'"
      name="close-outline"
      (click)="closeStoryViewer()"
    ></ion-icon>
  </div>

  <swiper-container #slider (click)="nextStoryItem()" (swiperslidechange)="onChangeSlide()">
    <swiper-slide *ngFor="let slide of slides" [style.backgroundColor]="slide.backgroundColor">
      <img *ngIf="slide.imageTop" [src]="slide.imageTop" />

      <div class="content" *ngIf="!slide.imageFull" [class.black]="slide?.closeTheme === 'dark'" [class.noImageTop]="!slide.imageTop">
        <p class="title" [style.color]="slide?.textColor" [innerHTML]="slide.title"></p>
        <p
          class="description"
          *ngFor="let paragraph of slide.textParagraphs"
          [class.listExists]="slide.listItems"
          [style.color]="slide?.textColor"
        >
          {{ paragraph }}
        </p>
        <ul class="slide-list" [class.paragraphExists]="slide.textParagraphs">
          <li *ngFor="let listItem of slide.listItems">{{listItem}}</li>
        </ul>
      </div>

      <div *ngIf="slide.imageMiddle" class="image-middle">
        <img [src]="slide.imageMiddle" />
      </div>

      <div *ngIf="slide.imageBottom" class="image-bottom">
        <img [src]="slide.imageBottom" />
      </div>

      <div *ngIf="slide.imageFull" class="image-full">
        <img [src]="slide.imageFull" />
      </div>

      <div class="block-bottom">
        <p *ngIf="slide.buttonTitleAbove" class="titleAbove" [style.color]="slide?.textColor">{{ slide.buttonTitleAbove }}</p>
        <p *ngIf="slide.buttonTextAbove" class="textAbove" [style.color]="slide?.textColor">{{ slide.buttonTextAbove }}</p>

        <button
          *ngIf="slide.buttonText"
          [style.color]="slide?.buttonTextColor"
          [style.backgroundColor]="slide?.buttonColor"
          (click)="clickButton(slide, $event)"
        >
          <app-icon *ngIf="slide.buttonIcon" icon="vk-white" class="button-icon icon"></app-icon>
          {{ slide.buttonText }}
        </button>

        <button *ngIf="slide.hasRefferalButton" (click)="copyToClipboard($event)">
          {{profile.partnerInfo?.code}}
          <app-icon icon="copy-clipboard-black" class="button-icon icon"></app-icon>
        </button>

        <p *ngIf="slide.buttonTextBelow" class="textBelow" [style.color]="slide?.textColor">{{ slide.buttonTextBelow }}</p>
      </div>
    </swiper-slide>
  </swiper-container>
</div>
